<template>
    <b-container fluid>
        <b-row>
            <b-col offset-md='3' md = '6'>
                <b-card
                header="Change Mobile Number"
                header-tag="header"
                >
                    <b-card-body>
                        <p class="alert alert-danger" style="padding:5px" v-show="error"><span> {{ errorMessage }}</span></p>
                        <p class="alert alert-success" style="padding:5px" v-show="success"> OTP sent in your phone whose validity is 3 minutes!</p>
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(sendOtp)" @reset.prevent="reset" >
                                <b-row>
                                    <b-col lg="8" sm="8">
                                        <ValidationProvider name="Mobile" vid='mobile_no' rules="required|min:11|max:11">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            :label="$t('user.mobile_no')"
                                            label-for="mobile_no"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <b-form-input
                                            id="mobile_no"
                                            type="number"
                                            v-model="changeMobile.mobile_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" sm="4">
                                        <button class="btn btn-primary btn-block" style="margin-top:35px" type="submit">{{ $t('globalTrans.send')}}</button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(updateMobile)" @reset.prevent="reset" >
                                <b-row>
                                    <b-col lg="12" sm="12">
                                        <ValidationProvider name="Mobile" vid='otp' rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            :label="$t('externalUserIrrigation.otp')"
                                            label-for="otp"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <b-form-input
                                            id="otp"
                                            v-model="changeMobile.otp"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col offset-md="3" md='6'>
                                        <div class="">
                                            <button class="btn btn-primary btn-block" type="submit">{{ $t('globalTrans.update')}}</button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { mobileUpdate, basicMobileChange, changeMobileApi } from '../../api/routes'

export default {
    name: 'ChangeMobile',
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            invalidNum: false,
            error: false,
            errorMessage: '',
            success: false,
            loading: false,
            changeMobile: {
                mobile_no: '',
                otp: '',
                id: ''
            }
        }
    },
    computed: {
        getMobile () {
            return this.$store.state.Auth.authUser
        }
    },
    created () {
        this.changeMobile.mobile_no = this.getMobile.mobile_no
        this.changeMobile.id = this.getMobile.id
    },
    methods: {
        async sendOtp () {
            try {
                this.loading = true
                const result = await RestApi.postData(authServiceBaseUrl, changeMobileApi, this.changeMobile)
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: result.message
                    })
                    this.success = true
                } else {
                    this.$refs.form.setErrors(result.errors)
                }
            } catch (error) {
                this.$toast.success({
                    title: 'Error',
                    message: 'Operation error!'
                })
            }
        },
        async updateMobile () {
            const result = await RestApi.postData(authServiceBaseUrl, mobileUpdate, this.changeMobile)
            if (result.success) {
                await RestApi.postData(irriSchemeServiceBaseUrl, basicMobileChange, this.changeMobile)
                this.$toast.success({
                    title: 'Success',
                    message: 'Number successfully changed.You are being logged out.'
                })
                this.error = false
                this.success = false
                setTimeout(() => this.logout(), 2000)
            } else if (result.errors) {
                this.$refs.form.setErrors(result.errors)
                this.$refs.form1.setErrors(result.errors)
            } else {
                this.error = true
                this.errorMessage = result.message
            }
        },
        logout () {
            this.$store.dispatch('Auth/updateAuthUser', null)
            this.$store.commit('ExternalUserIrrigation/mutateExternalCommonProperties', {
                hasDropdownLoaded: false
            })
            this.$router.push('/auth/login')
        }
    }
}
</script>
<style scoped>
    .card-header {
        font-size: 18px;
        color: black;
        font-weight: bold;
    }
</style>
